import React from "react";
import styled from "@emotion/styled";
import SEO from "../components/seo";
import NotFound from "../assets/NotFound";
import { Container } from "react-grid-system";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <Wrapper>
      <SEO title="404: Not found" />
      <Container>
        <NotFound />
      </Container>
    </Wrapper>
  </Layout>
);

const Wrapper = styled("div")`
  display: flex;
  height: 100vh;
  margin-top: -80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    height: auto;
    max-width: 100%;
  }
`;

export default NotFoundPage;
